import React from 'react';

// Import styles 
import styles from './Inventory.module.css';

// Import components
// import InventoryItem from '../components/InventoryItem';
import HeadingOne from '../components/HeadingOne';
import ParagraphOne from '../components/ParagraphOne';
import HeadingTwo from '../components/HeadingTwo';

// Import media
// import InventoryItemImageOne from '../assets/test/gmc_sierra.avif';
// import InventoryItemImageTwo from '../assets/gears.jpg';
// import InventoryItemImageThree from '../assets/open_trucks.jpeg';

function Inventory() {
    return ( 
        <div className={styles.inventoryPage}>
            <HeadingOne additionalClassNames={styles.inventoryTitle}>INVENTORY</HeadingOne>
            <ParagraphOne additionalClasses={styles.introParagraph}>Welcome to our Diesel Truck Inventory page! Here at C&R Truck and Trailer Diesel Repair, we specialize in buying, selling, and repairing high-quality diesel trucks. Whether you're looking for a reliable workhorse or a restoration project, our diverse selection has something for everyone. Browse our current inventory to find the perfect truck that meets your needs, and don’t hesitate to reach out with any questions. Let us help you hit the road in style and performance!</ParagraphOne>

            <HeadingTwo>Inventory coming soon!</HeadingTwo>

            {/* <div className={styles.inventoryItemsContainer}>
                <InventoryItem image={InventoryItemImageOne} title={'2024 GMC Sierra'} model={'SLT Trim'} price={40000} description={'A beautiful and fast truck you can enjoy today and flex to all of your friends and family'}/>

                <InventoryItem image={InventoryItemImageOne} title={'2023 GMC Sierra'} model={'SLT Trim'} price={40000} description={'A beautiful and fast truck you can enjoy today and flex to all of your friends and family'}/>

                <InventoryItem image={InventoryItemImageOne} title={'2022 GMC Sierra'} model={'SLT Trim'} price={40000} description={'A beautiful and fast truck you can enjoy today and flex to all of your friends and family'}/>

                <InventoryItem image={InventoryItemImageOne} title={'2021 GMC Sierra'} model={'SLT Trim'} price={40000} description={'A beautiful and fast truck you can enjoy today and flex to all of your friends and family'}/>

                <InventoryItem image={InventoryItemImageOne} title={'2020 GMC Sierra'} model={'SLT Trim'} price={40000} description={'A beautiful and fast truck you can enjoy today and flex to all of your friends and family'}/>

                <InventoryItem image={InventoryItemImageOne} title={'2019 GMC Sierra'} model={'SLT Trim'} price={40000} description={'A beautiful and fast truck you can enjoy today and flex to all of your friends and family'}/>

                <InventoryItem image={InventoryItemImageOne} title={'2018 GMC Sierra'} model={'SLT Trim'} price={40000} description={'A beautiful and fast truck you can enjoy today and flex to all of your friends and family'}/>

                <InventoryItem image={InventoryItemImageOne} title={'2017 GMC Sierra'} model={'SLT Trim'} price={40000} description={'A beautiful and fast truck you can enjoy today and flex to all of your friends and family'}/>

                <InventoryItem image={InventoryItemImageOne} title={'2016 GMC Sierra'} model={'SLT Trim'} price={40000} description={'A beautiful and fast truck you can enjoy today and flex to all of your friends and family'}/>

                <InventoryItem image={InventoryItemImageOne} title={'2015 GMC Sierra'} model={'SLT Trim'} price={40000} description={'A beautiful and fast truck you can enjoy today and flex to all of your friends and family'}/>

                <InventoryItem image={InventoryItemImageOne} title={'2014 GMC Sierra'} model={'SLT Trim'} price={40000} description={'A beautiful and fast truck you can enjoy today and flex to all of your friends and family'}/>
            </div> */}
        </div>
    );
}

export default Inventory;